import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/legacy/image";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { omit, isArray, isNil } from "lodash";
import cn from "classnames";
import { type UrlObject } from "url";

import { useUser } from "@auth0/nextjs-auth0/client";
import ExploreIcon from "./slash-circle.svg";
import DownIcon from "./chevron-down.svg";
import UpIcon from "./chevron-up.svg";
import PlusIcon from "./plus-lg.svg";
import MinusIcon from "./dash-lg.svg";
import ExclamationIcon from "./exclamation-triangle.svg";
import PersonIcon from "./person.svg";
import UserNav from "@/organisms/UserNav";

import s from "./style.module.css";

export const menuAtom = atomWithStorage("menu", {
  explore: true,
  alerts: true,
  triggers: true,
  peopleHealth: false,
  custom: true,
  communicationHealth: false,
  legacy: true,
});

///////////////////////////////
// <LeftPaneNav />
//////////////////////////////
function LeftPaneNav() {
  return (
    <div id="left-pane" className={s.container}>
      <div className={s.logo}>
        <Image src="/logo.svg" width={190} height={50} />
      </div>

      <nav id="left-pane-nav" className={s.navigation}>
        <ul>
          <Alerts />
          <Triggers />
          <Explore />

          <NavItem id="request-a-report" url="/request-a-report">
            <PersonIcon />
            Request a Report
          </NavItem>
        </ul>
      </nav>

      <UserNav />
    </div>
  );
}

function Alerts() {
  const [menuState, setMenuState] = useAtom(menuAtom);
  const handleToggle = () =>
    setMenuState({
      ...menuState,
      alerts: !menuState.alerts,
    });
  return (
    <li
      className={cn({
        [s.open]: menuState.alerts,
      })}
    >
      <span onClick={handleToggle}>
        <ExclamationIcon />
        Alerts
        <a className={s.expandButton}>
          {menuState.alerts ? <DownIcon /> : <UpIcon />}
        </a>
      </span>

      <ul>
        <NavItem id="active-alerts" url="/alerts/active">
          Active Alerts
        </NavItem>
        <NavItem id="alert-settings" url="/alerts/settings">
          Settings
        </NavItem>
      </ul>
    </li>
  );
}

function Triggers() {
  const [menuState, setMenuState] = useAtom(menuAtom);
  const handleToggle = () =>
    setMenuState({
      ...menuState,
      triggers: !menuState.triggers,
    });
  return (
    <li
      className={cn({
        [s.open]: menuState.triggers,
      })}
    >
      <span onClick={handleToggle}>
        <ExclamationIcon />
        Triggers
        <a className={s.expandButton}>
          {menuState.triggers ? <DownIcon /> : <UpIcon />}
        </a>
      </span>

      <ul>
        <NavItem id="triggers-active" url="/triggers/active">
          Active
        </NavItem>
        <NavItem id="triggers-manage" url="/triggers/manage">
          Manage
        </NavItem>
      </ul>
    </li>
  );
}

function Explore() {
  const [menuState, setMenuState] = useAtom(menuAtom);
  const handleToggle = () =>
    setMenuState({
      ...menuState,
      explore: !menuState.explore,
    });

  return (
    <li className={cn({ [s.open]: menuState.explore })}>
      <span onClick={handleToggle}>
        <ExploreIcon />
        Explore
        <a className={s.expandButton}>
          {menuState.explore ? <DownIcon /> : <UpIcon />}
        </a>
      </span>

      <ul>
        <NavItem id="team-overview" url="/team">
          Team Overview
        </NavItem>
        <NavItem id="activity-breakout" url="/activity-breakout">
          Activity Breakout
        </NavItem>
        <NavItem id="cost-to-serve" url="/cost-to-serve">
          Cost to Serve
        </NavItem>
        <NavItem id="customer-prioritization" url="/customer-prioritization">
          Customer Prioritization
        </NavItem>
        <NavItem id="customer-details" url="/customer-details">
          Customer Details
        </NavItem>
        <NavItem id="customer-breakout" url="/customer-breakout">
          Customer Breakout
        </NavItem>
        <NavItem id="dashboard-builder" url="/dashboard-builder">
          Dashboard Builder
        </NavItem>
        <LegacyMenu />
        <CustomMenu />
      </ul>
    </li>
  );
}

function LegacyMenu() {
  const [menuState, setMenuState] = useAtom(menuAtom);
  const isMenuOpen = menuState.legacy;

  const handleToggle = () =>
    setMenuState({
      ...menuState,
      legacy: !menuState.legacy,
    });

  return (
    <li className={cn({ [s.open]: isMenuOpen })}>
      <span onClick={handleToggle}>
        <a>Legacy</a>
        <PlusMinusButton open={isMenuOpen} />
      </span>
      <ul>
        <NavItem id="burnout" url="/aggregates/burnout">
          Burnout
        </NavItem>
        <NavItem id="manager-1-on-1-time" url="/aggregates/manager-1-on-1-time">
          Manager 1:1 Time
        </NavItem>
        <NavItem id="focus-time" url="/aggregates/focus-time">
          Focus Time
        </NavItem>
        <NavItem id="active-hours" url="/aggregates/active-hours">
          Active Hours
        </NavItem>
        <NavItem id="connectivity" url="/aggregates/connectivity">
          Connectivity
        </NavItem>
        <NavItem id="responsiveness" url="/aggregates/responsiveness">
          Responsiveness
        </NavItem>
        <NavItem id="time-distribution" url="/aggregates/time-distribution">
          Time Distribution
        </NavItem>
        <NavItem id="customizable-table" url="/customizable-table">
          Customizable Table
        </NavItem>
      </ul>
    </li>
  );
}

function CustomMenu() {
  const [menuState, setMenuState] = useAtom(menuAtom);
  const isMenuOpen = menuState.custom;
  const handleToggle = () =>
    setMenuState({
      ...menuState,
      custom: !menuState.custom,
    });

  return (
    <li className={cn({ [s.open]: isMenuOpen })}>
      <span onClick={handleToggle}>
        <a>Custom</a>
        <PlusMinusButton open={isMenuOpen} />
      </span>
      <ul>
        <NavItem id="development-health" url="/development-health">
          Development Health
        </NavItem>
        <NavItem id="ticketing" url="/aggregates/ticketing">
          Ticketing
        </NavItem>
        <NavItem
          id="internal-time-consumed"
          url="/aggregates/internal-time-consumed"
        >
          Internal Time Consumed
        </NavItem>
        <NavItem
          id="internal-time-consumed"
          url="/aggregates/employee-turnover"
        >
          Employee Turnover
        </NavItem>
        <NavItem id="org-chart" url="/org-chart">
          Org Chart
        </NavItem>
      </ul>
    </li>
  );
}

interface NavItemProps {
  id: string;
  url: string;
  children: React.ReactNode;
}

function NavItem({ id, url, children }: NavItemProps) {
  const router = useRouter();
  const { user } = useUser();

  const activeClass = router.pathname.match(`^${url}`) ? s.active : null;

  return (
    isVisible(user, id) && (
      <li className={cn(activeClass)}>
        <Link href={hrefFor(url, router.query)}>{children}</Link>
      </li>
    )
  );
}

function PlusMinusButton({ open }: { open: boolean }) {
  return (
    <a className={s.expandButton}>{open ? <MinusIcon /> : <PlusIcon />}</a>
  );
}

/////////////////////////////////
// Utility Functions
/////////////////////////////////
function isVisible(user: any, id: string) {
  const config = (user?.csMetadata?.menu || {})[id];
  const role = user?.role;
  const email = user?.email;

  if (role === "super_admin") {
    // Super admins always see all entries
    return true;
  }

  if (isNil(config)) {
    return false;
  }

  if (config.roles === "*") {
    return true;
  }

  if (isArray(config.roles) && config.roles.includes(role)) {
    return true;
  }

  if (isArray(config.users) && config.users.includes(email)) {
    return true;
  }

  return false;
}

function hrefFor(pathname: string, currentParams: object) {
  return {
    pathname: pathname,
    query: omit(currentParams, [
      "metricId",
      "customTargets[time-distribution-external]",
    ]),
  } as UrlObject;
}

export default LeftPaneNav;
