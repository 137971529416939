import React, {
  useState,
  useEffect,
  useCallback,
  type ChangeEventHandler,
  type ChangeEvent,
} from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import Link from "next/link";
import Image from "next/legacy/image";
import { useAtom } from "jotai";
import { sortBy, isNil } from "lodash";
import cookies from "browser-cookies";
import { Utm } from "utm-extractor";
import { Router } from "next/router";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { useUser } from "@auth0/nextjs-auth0/client";

import {
  adminAtom,
  ADMIN_VIEWER,
  dashboardSettingsAtom,
  dashboardConfigAtom,
  defaultDashboardConfigAtom,
} from "../../store";
import { Role } from "@/lib/gql/graphql";
import { getUsers } from "@/lib/graphql";
import NewLeftPaneNav from "@/organisms/NewLeftPaneNav";
import LeftPaneNav from "@/organisms/LeftPaneNav";
import LegacyInput from "@/atoms/LegacyInput";
import Loading from "@/atoms/Loading";
import { ErrorBoundary } from "@/lib/errors";

import styles from "./style.module.css";

type UserInfo = {
  id: string;
  email: string;
  name: string;
  role: Role;
  company: CompanyInfo;
};

type CompanyInfo = {
  id: number;
  name: string;
};

type CsUserProfile = {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  role: Role;
  access_pending: boolean;
  company_id: number;
  companyName: string;
  token: string;
  explo_customer_token: string;
  csMetadata: UserCsMetadata;
  isDemoUser: boolean;
  actsAs: boolean;
  actingAs: UserInfo;
  actualAuthedUser: UserInfo;
};

interface HeaderTitleProps {
  pathname: string;
  user: CsUserProfile;
  users: any;
  viewingDashboardAs: string;
  isDemoMode: boolean;
  adminInitialized: boolean;
  onClickAdmin: () => void;
  onChangeUser: ChangeEventHandler<HTMLSelectElement>;
  onZingGridSearch: ChangeEventHandler<HTMLInputElement>;
}

function HeaderTitle({
  pathname,
  user,
  users,
  viewingDashboardAs,
  isDemoMode,
  adminInitialized,
  onClickAdmin,
  onChangeUser,
  onZingGridSearch,
}: HeaderTitleProps) {
  switch (pathname) {
    case "/integrations/tools":
      return (
        <>
          <div className={styles.header}>
            <div className={styles.breadcrumb}>
              <Link
                href="/integrations"
                className={styles.breadcrumbChevronLeft}
              >
                <Image
                  src="/assets/images/chevron-left.svg"
                  width="20"
                  height="20"
                />
              </Link>
              <p>Tools Integration</p>
            </div>
          </div>
          <div className={styles.greyLine} />
        </>
      );
    case "/customizable-table":
      if (user?.role === "super_admin") {
        return (
          <>
            <div className={styles.header}>
              <div
                onClick={onClickAdmin}
                className={classNames(styles.headerTitleContainer, {
                  [styles.demoMode]: isDemoMode,
                })}
              >
                {adminInitialized ? (
                  <AdminDashboardDropdown
                    onChange={onChangeUser}
                    users={users}
                  />
                ) : (
                  viewingDashboardAs
                )}
              </div>

              <div className={styles.searchField}>
                <LegacyInput
                  id="zing-grid-search-input"
                  placeholder="Search"
                  onChange={onZingGridSearch}
                />
              </div>
            </div>
          </>
        );
      }
      return (
        <div className={styles.header}>
          <div className={styles.headerTitleContainer}>
            {user?.fullName ? `${user.fullName} - Team report` : "Team report"}
          </div>

          <div className={styles.searchField}>
            <LegacyInput
              id="zing-grid-search-input"
              placeholder="Search"
              onChange={onZingGridSearch}
            />
          </div>
        </div>
      );
    default:
      return;
  }
}

interface AdminDashboardDropdownProps {
  onChange: ChangeEventHandler<HTMLSelectElement>;
  users: any;
}

function AdminDashboardDropdown({
  onChange,
  users,
}: AdminDashboardDropdownProps) {
  return (
    <select className={styles.adminDashboardDropdown} onChange={onChange}>
      <option key="ADMIN" value="ADMIN">
        ADMIN
      </option>
      {sortBy(users, ["teamName"]).map((u) => {
        return (
          <option key={u.email} value={u.email}>
            {u.teamName} - {u.firstName} {u.lastName} ({u.email})
          </option>
        );
      })}
    </select>
  );
}

interface Props {
  children: JSX.Element;
}

type UserCsMetadata = {
  has_integration: boolean;
  has_crm_integration: boolean;
  can_manage_integrations: boolean;
  modules: object;
  dashboard_config: object;
  default_dashboard_config: object;
  menu: object;
  l10n: object;
};

function AuthedLayout({ children }: Props) {
  const router = useRouter();
  const [adminState, setAdminState] = useAtom(adminAtom);
  const [
    adminDashboardDropdownInitialized,
    setAdminDashboardDropdownInitialized,
  ] = useState(false);
  const [dashboardSettings, setDashboardSettings] = useAtom(
    dashboardSettingsAtom,
  );
  const [_dashboardConfig, setDashboardConfig] = useAtom(dashboardConfigAtom);
  const [_defaultDashboardConfig, setDefaultDashboardConfig] = useAtom(
    defaultDashboardConfigAtom,
  );
  const [
    zingGridColumnClickListenerAdded,
    setZingGridColumnClickListenerAdded,
  ] = useState(false);

  const { isLoading, error, user: userProfile } = useUser();

  const user = userProfile as CsUserProfile;
  const userMetadata = user?.csMetadata as UserCsMetadata;
  const canManageIntegrations = userMetadata?.can_manage_integrations;

  useEffect(() => {
    if (userMetadata) {
      setDashboardConfig(userMetadata.dashboard_config);
      setDefaultDashboardConfig(userMetadata.default_dashboard_config);
    }
  }, [userMetadata, setDashboardConfig, setDefaultDashboardConfig]);

  useEffect(() => {
    async function initializeAuth() {
      if (!isLoading && (error || !user)) {
        if (error?.message === "") {
          // The call to fetch the user's profile failed. This usually means that the
          // session cookie expired and needs to be refreshed. Logging out will redirect
          // to the Auth0 login page. In most cases the login page will still have a valid
          // session cookie and automatically re-auth the user's session. If that's not
          // the case the user will be presented with the login form.
          const msg = "Call to /api/auth/me failed. Attempting to re-auth.";
          datadogLogs.logger.warn(msg, {}, error);
          console.warn(msg);
        } else {
          // It's unclear why auth failed. If we end up here it probably warrants
          // investigation. We still attempt to refresh the session using the logout/login
          // method described above.
          const msg = "Unknown auth error: attempting to re-auth";
          datadogRum.addError(new Error(msg), { user, error });
          console.error(msg);
        }
        router.push("/api/auth/login");
      } else if (!isLoading && user?.role === "super_admin") {
        const isDemoMode = cookies.get("_cs_demo_mode") === "true";
        setAdminState({
          ...adminState,
          viewingDashboardAs: ADMIN_VIEWER,
          isDemoMode,
        });
      }

      // Redirects
      if (!canManageIntegrations && !isLoading) {
        if (router.pathname.includes("integrations")) {
          router.push("/");
        }
      }

      const skipAnalytics =
        user?.email?.includes("demo.culturescience.io") ||
        user?.email?.includes("demo.lumopath.ai") ||
        navigator?.userAgent?.includes("DatadogSynthetics") ||
        process.env.NODE_ENV === "development";

      // Segment
      if (skipAnalytics) {
        window.analytics = {
          use: () => undefined,
          load: () => undefined,
          group: () => undefined,
          alias: () => undefined,
          ready: () => undefined,
          reset: () => undefined,
          user: () => undefined,
          debug: () => undefined,
          on: () => undefined,
          timeout: () => undefined,
          trackLink: () => undefined,
          trackForm: () => undefined,
          addIntegration: () => undefined,
          setAnonymousId: () => undefined,
          init: () => undefined,
          identify: () => undefined,
          page: () => undefined,
          track: () => undefined,
        };
      }

      if (!isLoading && user) {
        const actualAuthedUser = user.actualAuthedUser;
        const actingAs = user.actingAs;

        // DataDog
        if (datadogRum.getInitConfiguration()) {
          datadogRum.setUser({
            ...actualAuthedUser,
            actingAs,
          });

          if (!skipAnalytics) {
            datadogRum.startSessionReplayRecording();
          }
        }

        // Pendo
        if (!skipAnalytics) {
          // @ts-ignore
          window.pendo.initialize({
            visitor: {
              id: actualAuthedUser.email,
              email: actualAuthedUser.email,
              name: actualAuthedUser.name,
              role: actualAuthedUser.role,
              companyId: actualAuthedUser.company.id,
              companyName: actualAuthedUser.company.name,
              actingAs: !isNil(actingAs),
              actingAsId: actingAs?.id,
              actingAsEmail: actingAs?.email,
              actingAsName: actingAs?.name,
              actingAsRole: actingAs?.name,
              actingAsCompanyId: actingAs?.company?.id,
              actingAsCompanyName: actingAs?.company?.name,
            },
            account: actingAs?.company || actualAuthedUser?.company,
          });
        }

        // Segment
        window.analytics?.identify(actualAuthedUser.id, {
          ...actualAuthedUser,
          actingAs,
        });
        const utm = new Utm(router.asPath);
        const utmValues = utm.get();
        window.analytics?.page(router.asPath, {
          context: {
            campaign: {
              // https://segment.com/docs/connections/destinations/catalog/mixpanel/#utm-campaign-parameters
              name: utmValues.utm_campaign,
              source: utmValues.utm_source,
              medium: utmValues.utm_medium,
              term: utmValues.utm_term,
              content: utmValues.utm_content,
            },
          },
        });

        // Track client-side page views with Segment
        const routeChangeCompleteEventListener = (url: string) => {
          window.analytics?.page(url);
        };
        Router.events.on(
          "routeChangeComplete",
          routeChangeCompleteEventListener,
        );
        return () => {
          Router.events.off(
            "routeChangeComplete",
            routeChangeCompleteEventListener,
          );
        };
      }
    }

    initializeAuth();
  }, [isLoading, user, userMetadata]);

  // Respect search value when undoing column sort
  function handleColumnClick() {
    const searchValue = document.querySelector<HTMLInputElement>(
      "#zing-grid-search-input",
    )?.value;

    if (searchValue) {
      const zgRef = document.querySelector("zing-grid");
      // zgRef.refresh()
      // @ts-ignore
      zgRef.searchGrid(searchValue);
    }
  }

  const handleZingGridSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const zgRef = document.querySelector("zing-grid");
    const searchTerm = e.target.value;
    // @ts-ignore
    zgRef.searchGrid(searchTerm);
    setDashboardSettings({ ...dashboardSettings, searchTerm });

    if (!zingGridColumnClickListenerAdded) {
      // @ts-ignore
      zgRef.addEventListener("column:click", handleColumnClick);
      setZingGridColumnClickListenerAdded(true);
    }
  };

  async function handleAdminDashboardToggleClicked() {
    // @ts-ignore
    const { users } = await getUsers();
    setAdminState({ ...adminState, adminDashboardDropdownUsers: users });
    setAdminDashboardDropdownInitialized(true);
  }

  const handleAdminDashboardDropdownOnSelect = async (
    e: ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedUserEmail = e.target.value;
    setAdminState({ ...adminState, viewingDashboardAs: selectedUserEmail });
  };

  if (!user) {
    return <Loading delay={2000} />;
  }

  return (
    <div className={styles.root}>
      <Navigation user={user} />

      <div className={styles.container}>
        <HeaderTitle
          pathname={router.pathname}
          user={user as CsUserProfile}
          onZingGridSearch={handleZingGridSearch}
          users={adminState.adminDashboardDropdownUsers}
          viewingDashboardAs={adminState.viewingDashboardAs}
          isDemoMode={adminState.isDemoMode}
          adminInitialized={adminDashboardDropdownInitialized}
          onClickAdmin={handleAdminDashboardToggleClicked}
          onChangeUser={handleAdminDashboardDropdownOnSelect}
        />
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </div>
  );
}

function Navigation({ user }: { user: CsUserProfile }) {
  const modules = user?.csMetadata?.modules || {};

  if (modules["new-menu"]) {
    return <NewLeftPaneNav />;
  }
  return <LeftPaneNav />;
}

export default AuthedLayout;
