import { isNil } from "lodash";
import utc from "dayjs/plugin/utc";
import dayjs, { Dayjs } from "dayjs";
import { Role } from "@/lib/gql/graphql";

dayjs.extend(utc);
export const MONTH_NUM_TO_SHORT_NAME = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export function minutesToHhMm(
  val: number,
  stripMinutesForLargeNumbers = true,
  pad = true,
) {
  const isNegative = val < 0;
  const hours = Math.floor(Math.abs(val / 60));
  const minutes = Math.round(Math.abs(val % 60));
  let formattedHourValue = hours.toLocaleString();
  if (pad) {
    formattedHourValue = formattedHourValue.padStart(2, "0");
  }
  const formattedHours = `${isNegative ? "-" : ""}${formattedHourValue}`;
  const formattedMinutes = `:${String(minutes).padStart(2, "0")}`;
  return `${formattedHours}${
    stripMinutesForLargeNumbers && hours > 100 ? "" : formattedMinutes
  }`;
}

export function formatMinutes(value: number) {
  if (isNil(value)) {
    return null;
  }

  const hours = Math.floor(value / 60.0);
  const minutes = Math.round(value % 60.0);
  const formattedHours = `${hours.toLocaleString()}h`;
  const formattedMinutes = ` ${minutes.toLocaleString()}m`;
  return `${formattedHours}${
    hours < 100 && minutes > 0 ? formattedMinutes : ""
  }`;
}

export function getCents(number: number) {
  const decimalString = number.toString().split(".")[1];
  return (decimalString || "00").padEnd(2, "0").slice(0, 2);
}

export function getPreviousPeriod(dateRange: Dayjs[]) {
  const start = dateRange[0];
  const end = dateRange[1];
  const period =
    start === start.startOf("month") && end === end.endOf("month")
      ? "month"
      : "week";
  return [
    start.subtract(end.diff(start), "milliseconds").startOf(period),
    start.subtract(2, "day").endOf(period),
  ];
}

export const version = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "dev";

let envVal: "production" | "staging" | "development";
switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
  case "production":
    envVal = "production";
    break;
  case "preview":
    envVal = "staging";
    break;
  default:
    envVal = "development";
    break;
}
export const env = envVal;

export function toolName(toolId: string, l10n: Record<string, string>) {
  switch (toolId) {
    case "all":
      return "All";
    case "asana":
      return "Asana";
    case "github":
      return "GitHub";
    case "slack":
      return "Slack";
    case "google_gmails":
      return "Email";
    case "google_calendar_events":
      return "Calendar";
    case "google_drive":
    case "google_drive_activities":
      return "Google Drive";
    case "jira":
      return "Jira";
    case "linear":
      return "Linear";
    case "pendo":
      return l10n?.pendo ?? "Pendo";
    case "ring_central":
      return "Ring Central";
    case "vonage":
      return "Vonage";
    case "zendesk":
      return "Zendesk";
  }
}

export function isManagerOrAbove(role: Role) {
  return [
    Role.SuperAdmin,
    Role.Admin,
    Role.ManagerIntegrations,
    Role.Manager,
    Role.IcWithTeamPermissionsAndIntegrations,
    Role.IcWithTeamPermissions,
  ].includes(role);
}
